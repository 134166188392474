<!--
 * @Author: wxy
 * @Describe: 
 * @LastEditors: wxy
 * @LastEditTime: 2021-06-20 15:46:21
-->
<template>
  <a-layout-content
    class="padding-left padding-top padding-right"
    :style="{
      minHeight: 'auto',
    }"
  >
      <router-view v-slot="{ Component }">
        <!-- <transition name="fade"> -->
        <component :is="Component" />
        <!-- </transition> -->
      </router-view>
  </a-layout-content>
</template>

<script>
import { defineComponent } from "vue";
export default defineComponent({});
</script>
