<!--
 * @Author: wxy
 * @Describe:
 * @LastEditors: wxy
 * @LastEditTime: 2021-06-20 00:59:00
-->
<template>
  <a-layout id="components-layout-demo-custom-trigger">
    <Sider />
    <a-layout ref="layoutContent" class="theme-body-color">
      <Header />
      <Wbreadcrumb />
      <WpageTabs />
      <Content />
    </a-layout>
  </a-layout>
  <!-- 回到顶部 -->
  <a-back-top
    v-if="layoutContent"
    :target="() => layoutContent.$el"
    :visibilityHeight="400"
  />
</template>
<script>
import { defineComponent, ref } from "vue";
import Header from "./Header/Header";
import Sider from "./Sider/Sider";
import Content from "./Content/Content";
import Wbreadcrumb from "@/components/Wbreadcrumb/Wbreadcrumb";
import WpageTabs from "@/components/WpageTabs/WpageTabs";

export default defineComponent({
  components: { Header, Sider, Content, Wbreadcrumb,WpageTabs },
  setup() {
    const layoutContent = ref();
    return {
      layoutContent,
    };
  },
});
</script>

<style lang="less" scoped>
#components-layout-demo-custom-trigger {
  height: 100vh;
}

.ant-back-top {
  bottom: 90px;
  right: 60px;
}
</style>
