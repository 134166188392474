/*
 * @Author: wxy
 * @Describe:
 * @LastEditors: wxy
 * @LastEditTime: 2021-04-14 01:15:55
 */

import { useRoute, onBeforeRouteUpdate } from "vue-router";
import { ref, readonly } from "vue";

export default function useBreadcrumb() {
  const route = useRoute();
  let _breadcrumbArr = ref([]);
  let _breadcrumbKey = ref("DataManage");

  let breadcrumbArr = readonly(_breadcrumbArr);
  let breadcrumbKey = readonly(_breadcrumbKey);

  const handlebreadcrumb = (breadcrumbName, key) => {
    _breadcrumbKey.value = key;
    if (!breadcrumbName) {
      _breadcrumbArr.value = [];
      return;
    }
    _breadcrumbArr.value = breadcrumbName
      .split(",")
      .filter((str) => str !== "主页");
  };

  // 初始化
  handlebreadcrumb(route.meta.breadcrumbNameArr, route.name);
  // 路由监听
  onBeforeRouteUpdate((to) => {
    handlebreadcrumb(to.meta.breadcrumbNameArr);
  });

  return { breadcrumbArr, breadcrumbKey };
}
