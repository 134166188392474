<!--
 * @Author: wxy
 * @Describe: 
 * @LastEditors: wxy
 * @LastEditTime: 2021-06-21 22:38:58
-->
<template>
  <div class="padding-left-xxl padding-top padding-bottom">
    <a-breadcrumb>
       <template #separator><span class="theme-text-color" > / </span></template>
      <a-breadcrumb-item>
        <Icon icon="HomeOutlined" class="theme-text-color" />
        <span class="theme-text-color">主頁</span>
        <!-- <WLink :to="{ name: 'index' }" tag="span"></WLink> -->
      </a-breadcrumb-item>
      <a-breadcrumb-item v-for="name in breadcrumbArr" :key="name">
        <span class="theme-text-contrast">{{ name }} </span>
      </a-breadcrumb-item>
    </a-breadcrumb>
  </div>
</template>

<script>
import useBreadcrumb from "./useBreadcrumb";
import { Icon } from "@/components/Wicon/Wicon";

export default {
  components: { Icon },
  setup() {
    const { breadcrumbArr } = useBreadcrumb();
    return { breadcrumbArr };
  },
};
</script>
